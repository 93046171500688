<div class="main_body">
	<h4 class="heading center">
		Contact Us Today
	</h4>

	<p>
		Our experienced team is here to discuss any 
		questions you might have and talk you through 
		the many services we offer.
	</p>
</div>

<div id="map">
	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15832.535667954144!2d5.2385625!3d7.2255625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa1f00e9712afb6bf!2sFairview%20Nigeria!5e0!3m2!1sen!2sng!4v1592667099660!5m2!1sen!2sng" 
	frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>

<p>
	<b>Locate us at:</b> 1, Ademola Adesida street, off Igbatoro road, Akure, Ondo State, Nigeria.
	<br>
	<b>For enquiries, send us an email</b> <a href="mailto:contact@fairviewnigeria.com">
		contact@fairviewnigeria.com
	</a> 
	<br>
	<b>Or call us on</b> <a href="tel:+2348038340495" >
		(234)-803-834-0495
	</a>, <a href="tel:+2349035933512"> (234)-903-593-3512</a>
	<br>
	<b>Business hours: </b> Sun - Sat (24 hours)
</p>

<div class="container">
	<div class="row">
		<form class="col s12" #contactForm="ngForm" (ngSubmit)="sendMessage(contactForm)">
			<div class="row">
				<div class="input-field col s12">
					<input id="full_name" type="text" class="validate" name="fullname"
					[(ngModel)]="mail.name" required>
					<label for="full_name">Full Name</label>
				</div>
			</div>
			<div class="row">
				<div class="input-field col s12">
					<input id="email" type="email" class="validate" name="contact_email"
					[(ngModel)]="mail.email" required>
					<label for="email">Your Email</label>
				</div>
			</div>
			<div class="row">
				<div class="input-field col s12">
					<input id="subject" type="text" class="validate" name="subject"
					[(ngModel)]="mail.subject" required>
					<label for="subject">Subject</label>
				</div>
			</div>
			<div class="row">
				<div class="input-field col s12">
					<textarea id="message" class="materialize-textarea" name="msg"
					[(ngModel)]="mail.message" required></textarea>
					<label for="message">What can we help you with?</label>
				</div>
			</div>

			<button class="btn btn-block col s12" type="submit" [disabled]="!contactForm.form.valid">
				SEND
			</button>
		</form>
	</div>
</div>