<ng-template #bannerCaption>
	<div class="caption">
		<h4 class="heading"> 
			Welcome to <span class="keep_together">Fairview Nigeria</span>
		</h4>
	
		<div class="cta"> 
			<mark>
				Discover our
				<a class="fv_purple-text text-light_af" [routerLink]="'/funeral'"> 
					Funerary Services 
				</a>
			</mark>
		</div>
	</div>
</ng-template>

<app-banner [images]="images" [slider]="true" 
[captionTemplate]="bannerCaption" [height]="500"></app-banner>

<div class="main_body">
	<h4 class="heading center">
		Arranging a funeral?		
	</h4>
	
	 <p class="center">
		At Fairview Nigeria, we know saying goodbye is never easy.
		We’re here to support and guide you through the process of making 
		funeral arrangements that are worthy of your loved ones.
		
		<button class="btn btn-block center" [routerLink]="'/contact'" type="button">
			Get in Touch
		</button>
	</p>
</div>

<ng-template #flipCardLeft>
	<div class="half">
		<h5 class="heading">
			Powering Histopathological Diagnosis
		</h5>
	
		<div class="cta">
			We have over 30 years of histopathological diagnostic expertise.
	
			<button class="btn btn-block hide-on-large-only" [routerLink]="['/pathology']" type="button">
				Discover More
			</button>

			<button class="btn btn-block hide-on-med-and-down" [routerLink]="['/pathology']" type="button">
				Discover Our Pathology Services
			</button>
		</div> 
	</div>
</ng-template>

<app-flip-card [leftCard]="flipCardLeft"
[right_bg]="flip_card_bg"></app-flip-card>