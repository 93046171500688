<ng-template #copyright>
  <span class="copyright_bottom">
    <a href="https://www.freepik.com/free-photo/solitude-copy-women-sorrow-casual-lonely_1239154.htm#query=grief&position=1&from_view=search">image credits: mindandi @ Freepik.com</a>
  </span>

  <div class="caption valign-wrapper">
    <h3 class="heading">
      How can we help?
    </h3>
  </div>
</ng-template>

<app-banner [images]="banner_img" [slider]="false" [captionTemplate]="copyright" 
[height]="400"></app-banner>

<div class="main_body justify_text">
  <p class="center">
    Experiencing the loss of a loved one can be daunting, figuring out the next steps is our way of 
    lifting some of the burden off you. In our experience, these are some of the common challenges 
    loved ones have trouble navigating.
  </p>

  <div class="container">
    <div class="row">
      <ul class="collapsible">
        <li>
          <div class="collapsible-header">
            <i class="material-icons">filter_drama</i>
            Breaking the News
          </div>
          
          <div class="collapsible-body">
            <span>              
                Having to deliver news on the death of someone is quite a heavy task, especially when you 
                realise that the words you choose are and the manner in which you relay the message will 
                forever be embedded in the mind of the receiver.
                <br><br>
                Our experts here at Fairview are experienced in softening the blow in the most compassionate 
                way possible, capable of dealing with various individuals and situations that may be presented.
                <br><br>
                We will be on hand to get you through this chapter, while ensuring you are also able 
                to process your own grief in a healthy manner.
                <br>

                Reach any of our experts on: <a href="tel:+2348038340495" >
                  (234)-803-834-0495				
                </a>
                ,
                <a href="tel:+2349035933512" >
                  (234)-903-593-3512		
                </a>              
            </span>
          </div>
        </li>
        
        <li>
          <div class="collapsible-header">
            <i class="material-icons">filter_drama</i>
            How do I make funeral arrangements through your website?
          </div>
          <div class="collapsible-body">
            <span>
              You can make funeral arrangements through our website by 
              contacting our funeral home and speaking with one of our 
              funeral directors. They will help you plan the funeral service 
              and make arrangements for the burial or cremation.
            </span>
          </div>
        </li>
        
        <li>
          <div class="collapsible-header">
            <i class="material-icons">filter_drama</i>
            Can I purchase funeral merchandise, such as caskets and urns, from you?
          </div>
          <div class="collapsible-body">
            <span>
              Yes, you can purchase funeral merchandise, such as caskets and urns, 
              through our website. We offer a variety of options to suit different 
              budgets and preferences. You can contact our funeral directors 
              for assistance.
            </span>            
          </div>
        </li>

        <li>
          <div class="collapsible-header">
            <i class="material-icons">filter_drama</i>
            What types of funeral services do we offer?
          </div>
          <div class="collapsible-body">
            <span>
              We offer a range of funeral services, including traditional funerals, 
              cremations, memorial services, and graveside services. We can also help 
              with special requests and customization to meet your individual needs 
              and preferences.
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>