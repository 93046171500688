<div class="navbar-fixed">
    <nav>
		<a data-target="slide-out" class="sidenav-trigger">
			<i class="medium material-icons">menu</i>
		</a>

		<div class="nav-wrapper">
			<ul>
				<li>
					<a href="home" class="banner_img">
						<img src="../../../assets/images/logo/banner_transparent.png">
					</a>
				</li>
				<span class="hide-on-med-and-down">
					<li *ngFor="let link of nav_links">
						<a [href]="link.link">
							{{ link.title }}
						</a>
					</li>
				</span>
			</ul>
      	</div>
	</nav>
</div>


<ul id="slide-out" class="sidenav" style="transform: translateX(-105%);">
	<li>
		<a href="home">
			Home
		</a>
	</li>
	<li *ngFor="let link of nav_links">
		<a [href]="link.link">
			{{ link.title }}
		</a>
	</li>	
</ul>

	  