<footer class="page-footer">
	<div class="info">
		<div>
			<div>
				Contact Us
			</div>
			<a class="accent_yellow_text" href="tel:+2348038340495" >
				(234)-803-834-0495				
			</a>
			<br>
			<a class="accent_yellow_text" href="tel:+2349035933512" >
				(234)-903-593-3512		
			</a>
		</div>

		<div>
			<div>
				Email		
			</div>
			<a class="accent_yellow_text" href="mailto:contact@fairviewnigeria.com">
				contact@fairviewnigeria.com
			</a>
		</div>

		<div>
			<div>
				Business hours		
			</div>
			Sun - Sat 
			<br>
			(24 hours)
		</div>

		<div>
			<span>
				<a class="social"
				href="https://web.facebook.com/pages/category/Medical---Health/Fairview-Nigeria-371895583362174/"
				title="Facebook" target="_blank">
					<img src="../../../assets/images/fb.svg">
				</a>
			</span>

			<span>
				<a class="social"
				href="https://instagram.com/fairview_nigeria" title="Instagram" target="_blank">
					<img src="../../../assets/images/ig.svg" >
				</a>
			</span>

			<span>
				<a class="social"
				href="https://wa.me/2348038340495?text=Hi%20I%20am%20" title="WhatsApp" target="_blank">
					<img src="../../../assets/images/wa.svg">
				</a>
			</span>
		</div>

		<div class="footer-copyright">
			<!-- <a href="home" class="banner_img">
				<img src="../../../assets/images/logo/banner.jpg">
			</a> -->
			<span>
				1, Ademola Adesida street, off Igbatoro road, Akure, Ondo State, Nigeria.
			<br>
				© 2020 Fairview Nigeria. All Rights Reserved
			</span>	  		 
		</div>
	</div>
</footer>