<ng-template #bannerCaption>
	<div class="caption valign-wrapper">
		<span>
		<h4 class="heading"> 
			Helping You Say Goodbye
		</h4>
	
		<div class="body"> 
			<p>
				We believe in providing a personalised and 
				professional experience. Our services are 
				tailored to your needs and we cultivate 
				trust and respect every step of the way.
			</p>
		</div>
		</span>
	</div>
</ng-template>

<app-banner [images]="banner_img"
[captionTemplate]="bannerCaption"></app-banner>

<h4 class="heading big_heading accent_yellow">
	What we offer
</h4>

<ng-template #leftFlipCardI>
</ng-template>

<ng-template #rightFlipCardI>
	<div>
		<h5 class="heading">
			Funeral Services
		</h5>
		<span>
			<p>
				We help arrange funerals worthy of 
				your departed loved ones. We’re committed 
				to providing you with the best and most 
				affordable services.
			</p>
			
			<b>Our Services Include:</b>
		
			<ul>
				<li>Caskets</li>
				<li>Band & Pallbearers</li>
				<li>Hearse Services</li>
				<li>Funeral Decorations & Flowers</li>
			</ul>
		
			<button class="btn btn-block" [routerLink]="['/contact']" type="button">
				Contact Us
			</button>
		</span>
	</div>
</ng-template>

<app-flip-card [leftCard]="leftFlipCardI" [left_bg]="flip_bg"
[rightCard]="rightFlipCardI" [right_bg_col]="'white'"></app-flip-card>

<ng-template #leftFlipCardII>
	<div>
		<h5 class="heading center">
			Private Mortuary
		</h5>
		<p>
			The Peaceful Rest (TPR)" is our private mortuary. 
			We preserve and keep your departed ones in a secure
			 environment and provide additional services for 
			 honouring their memory such as embalming, makeup 
			 and presentation.
		</p>
	</div>
</ng-template>

<ng-template #rightFlipCardII>	
</ng-template>

<app-flip-card [leftCard]="leftFlipCardII" [left_bg_col]="'white'"
[rightCard]="rightFlipCardII" [right_bg]="flip_bg2" [reverse]="true"></app-flip-card>

<div class="portfolio accent_yellow">
	<div class="row">
		<div class="col s12 m4">
		  <div class="card">
			<div class="card-image">
			  <img [src]="si_url+ 'caskets/1.jpeg'">			  
			</div>
			<div class="card-content">
			  <p>
				We offer a wide variety of high-quality caskets in different styles, 
				sizes, and materials to suit any budget. Our caskets are carefully 
				crafted to provide a beautiful and dignified resting place for your loved ones.
			  </p>
			</div>
			<div class="card-action">
				<a class="waves-effect waves-light modal-trigger" href="#modal1"
				(click)="carousel_switch.emit('caskets')">
					Caskets
				</a>
			</div>
		  </div>
		</div>

		<div class="col s12 m4">
			<div class="card">
			  <div class="card-image">
				<img [src]="si_url+ 'hearse/1.jpeg'">
			  </div>
			  <div class="card-content">
				<p>
					Our team is trained and experienced in providing respectful and 
					dignified support for the casket during the funeral service. 
					We are dedicated to making the process as smooth and seamless 
					as possible for your family.
				</p>
			  </div>
			  <div class="card-action">
				<a class="waves-effect waves-light modal-trigger" href="#modal1"
				(click)="carousel_switch.emit('hearse')">
					Pall Bearers & Hearse
				</a>
			  </div>
			</div>
		</div>

		<div class="col s12 m4">
			<div class="card">
			  <div class="card-image">
				<img [src]="si_url+ 'accessories/1.jpeg'">
			  </div>
			  <div class="card-content">
				<p>
					We offer a variety of internment accessories to help you personalize 
					the final resting place of your loved one. Our selection includes 
					grave markers, grave liners, vaults, and more.
				</p>
			  </div>
			  <div class="card-action">
				<a class="waves-effect waves-light modal-trigger" href="#modal1"
				(click)="carousel_switch.emit('accessories')">
				Internment Accessories
				</a>
			  </div>
			</div>
		</div>
	</div>
	<!-- Modal Structure -->
	<div id="modal1" class="modal">
	  	<div class="modal-content">		
			<div class="carousel" id="carousel">
				<div class="carousel-container">
					<a class="carousel-item" *ngFor="let pi of all_si">
						<img src="{{pi.img}}">
					</a>
				</div>
			</div>
		
			<a (click)="carousel_switch.emit('prev')" class="carousel_nav left btn-floating btn waves-effect waves-light">
				<i class="large material-icons">arrow_back</i>
			</a>

			<a (click)="carousel_switch.emit('next')" class="carousel_nav right btn-floating btn waves-effect waves-light">
				<i class="large material-icons">arrow_forward</i>
			</a>
		</div>
	</div>
</div>